.archive {
   width: 67.5vw;
}

.archive-content {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   flex-direction: row;
}

.archive-project {
   width: 20vw;
   max-width: 20vw;
   height: 20vw;
   border: 5px solid #000000;
   margin-bottom: 2vw;
   transition: box-shadow 0.75s, transform 0.5s;
   box-shadow: 0;
}
.archive-project:hover {
   transform: translate(-5px, -5px);
   box-shadow: 5px 5px 0px 1px #c491db;
}

.project-link {
   display: inline;
   position: relative;
}
.project-link:hover {
   cursor: pointer;
}
.project-link:after {
   content: "";
   position: absolute;
   z-index: -1;
   right: 0;
   width: 0;
   bottom: -5px;
   background: #000;
   height: 2px;
   transition-property: width;
   transition-duration: 0.5s;
   transition-timing-function: ease-out;
}
.project-link:hover:after,
.project-link:focus:after,
.project-link:active:after {
   left: 0;
   right: auto;
   width: 100%;
}

.archive-project-description {
   padding: 0vh 5vh 0vh 2.5vh;
   margin-top: -1vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
}
.archive-project-description p {
   font-size: 1.1vw;
}
.archive-project-description h4 {
   font-size: 2vw;
}

