@font-face {
  font-family: 'CircularStd';
  src: local('CircularStd'), url(./circular_std/CircularStd-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./Avenir/AvenirLTStd-Light.otf) format('truetype');
}

h1, h2, h3, h6 {
  font-family: CircularStd, Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h4, h5, p {
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  font-weight:normal;
}


h1 {
  font-size: 70px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 35px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
} 

p {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

a {
  color: black;
  text-decoration: none;
}

body {
  margin: 0;
}