.education-header {
   display: flex;
   align-items: baseline;
   justify-content: space-between;
}

.education-content {
   margin-top: -3vh;
}

.certificate {
   display: inline;
   position: relative;
 }
 .certificate:hover {
    cursor: pointer;
 }
 .certificate:after {
   content: "";
   position: absolute;
   z-index: -1;
   right: 0;
   width: 0;
   bottom: -5px;
   background: #000;
   height: 2px;
   transition-property: width;
   transition-duration: 0.5s;
   transition-timing-function: ease-out;
 }
 .certificate:hover:after,
 .certificate:focus:after,
 .certificate:active:after {
   left: 0;
   right: auto;
   width: 100%;
 }