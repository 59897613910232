.footer {
   background-color: black;
   height: 30vh;
   margin-bottom: -5vh;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
   padding: 5vh 0 5vh 0;
}

.footer h3 {
   color: rgba(255, 255, 255, 0.842);
}

.contacts {
   display: flex;
   width: 30%;
   height: fit-content;
}

.contacts a {
   width: 33%;
   text-align: center;
   height: fit-content;
}

.footer h6 {
   color: rgba(255, 255, 255, 0.616);
   transition: color 0.5s;
}
.footer h6:hover {
   color: white;
}