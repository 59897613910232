.featured {
   margin-bottom: 20vh;
}


.project {
   display: flex;
   width: 67.5vw;
   height: 24.3vw;
   margin-bottom: 10vh;
   transition: box-shadow 0.75s, transform 0.5s;
   box-shadow: 0;
}
.project:hover {
   transform: translate(-5px, -5px);
   box-shadow: 5px 5px 0px 1px #c491db;
}

.project img {
   width: 50%;
}

.project-description {
   border: 5px solid #000000;
   width: 50%;
   max-width: 100%;
   padding: 0 2vw 1vw 2vw;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.project-description p {
   font-size: 1.1vw;
}
.project-description h4 {
   font-size: 2vw;
}


.project-link {
   display: inline;
   position: relative;
 }
 .project-link:hover {
    cursor: pointer;
 }
 .project-link:after {
   content: "";
   position: absolute;
   z-index: -1;
   right: 0;
   width: 0;
   bottom: -5px;
   background: #000;
   height: 2px;
   transition-property: width;
   transition-duration: 0.5s;
   transition-timing-function: ease-out;
 }
 .project-link:hover:after,
 .project-link:focus:after,
 .project-link:active:after {
   left: 0;
   right: auto;
   width: 100%;
 }